@import 'styles/variables';
@import 'styles/mixins';

.calculator {
  width: 100%;
  border-radius: 0 0 12px 12px;
}

.shadow {
  margin-top: -2px;
  position: absolute;
  width: 100%;
  height: 79%;
  box-shadow: 0 0 17px rgba(0, 26, 67, 0.12);
  clip-path: inset(-8px 0 0 0);
  border-radius: 0 30px 0 0;
}

.activeShadow {
  border-radius: 12px 0 0 0;
}

.tabs {
  display: flex;
  width: 100%;

  .tab {
    display: flex;
    background: white;
    box-shadow: 0 -5px 5px rgba(0, 26, 67, 0.25);
    border-radius: 30px 30px 0 0;
    width: 51%;
    padding: 10px 0 15px;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    font-size: 18px;
    height: 58px;
    align-items: center;

    .icon {
      margin: 0 8px 0 27px;
      width: 28px;
    }
  }

  .tab:nth-child(3) {
    position: absolute;
    right: 0;
  }
}

.activeTab {
  z-index: 3;
}

.tabName {
  text-align: center;
  margin: 0 auto;
}

.wrapper {
  padding-left: 12px;
}

.noticeWrapper {
  min-height: 48px;
  display: flex;

  @media (max-width: 345px) {
    max-height: 68px;
    min-height: 68px;
  }
}

.notice {
  margin-top: 5px;
}

.corner {
  position: absolute;
  top: 19px;
  z-index: 3;
  margin-left: -2px;
}

.buttonGetLoan {
  font-family: 'Poppins', sans-serif;
  width: 100%;
  height: 54px;
  background: $colorGold;
  border-radius: 0 0 30px 30px !important;
  box-shadow: 0 0 17px rgba(0, 26, 67, 0.07);
  border: none;
  cursor: pointer;
  font-weight: bold;
  margin-top: -2px;
  font-size: 18px;
  color: #fefefe;

  &:hover {
    background: $colorGoldActive;
  }
}

.coinCorner {
  position: absolute;
  top: 19px;
  z-index: 3;
  margin-left: -2px;
}

.selectionBlock {
  padding: 30px 20px 29px 20px;
  background: white;
  margin: -1px 0 -1px 0;
}

.selectionBlockCoin {
  border-radius: 30px 30px 0 0;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 23px;
  line-height: 31px;
  color: $colorTextBlackMain;
  margin-bottom: 17px;

  br {
    display: none;
  }
}

.titleLoans {
  font-size: 24px;
  align-self: center;
}

.inputsBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 205px;
  margin-bottom: 5px;
}

.ltvWrapper {
  position: relative;
  z-index: 1;
}

.ltv {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ltvTitle {
  font-size: $xsFontSize;
  color: $colorTextGray;
}

.ltvNotice {
  display: flex;
  position: relative;
  width: 12px;
  margin-left: 5px;
}

.ltvOptions {
  cursor: pointer;
  font-size: $xxsFontSize;
  display: flex;
  margin-left: 10px;
}

.inputFrom {
  margin-bottom: 16px;
}

.inputTo {
  margin-top: 7px;
}

.lineDotsWrapper {
  width: 100%;
  overflow: hidden;
  height: 1px;
  background-image: linear-gradient(to right, #737373 30%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 6px 1px;
  background-repeat: repeat-x;
}

.resultBlock {
  margin: 30px 0 35px 20px;
  display: flex;
  align-items: flex-start;
}

.resultBlockValue {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: $xxlFontSize;
  line-height: 31px;
  color: $colorTextBlackMain;

  > span {
    font-weight: normal;
  }
}

.getCoin {
  width: 40%;
}

.giveCoin {
  padding-left: 26px;
  border-left: 1px solid #a4a3aa50;

  .resultBlockValue {
    color: $colorGold;
  }
}

.errorAmount {
  position: absolute;
  top: 100%;
  font-size: $noteFontSize;
  line-height: 14px;
  color: $colorRedError;
  margin-top: 5px;
}

.for {
  font-size: 14px;
  line-height: 17px;
  font-weight: 800;
  color: $colorTextC3;
}

@include mobile {
  .title {
    br {
      display: block;
    }
  }

  .errorAmount {
    margin-top: 0;
  }

  .selectionBlock {
    padding: 15px 15px 25px 15px;
  }

  .resultBlockValue {
    font-size: 18px !important;
  }

  .noticeWrapper {
    display: flex;

    h2 {
      margin-top: 6px;
      margin-left: 4px;
    }
  }

  .notice {
    margin-top: 10px;
  }

  .inputsBlock {
    align-items: flex-end;
    flex-direction: column;
  }

  .ltvWrapper {
    margin-bottom: 4px;
    top: 5px;
  }

  .resultBlock {
    margin-top: 14px;
    margin-bottom: 25px;
    align-items: flex-start;
  }

  .inputFrom {
    margin-bottom: 8px;
  }

  .inputTo {
    margin-top: 5px;
  }
}

@include for-xs-tablet-and-tablet {
  .inputFrom {
    margin-bottom: 15px;
  }

  .inputTo {
    margin-top: 8px;
  }

  .inputsBlock {
    height: 201px;
  }
}

@include for-mobile-tablet-and-xs-tablet {
  .desktopOnly {
    display: none;
  }
}

@include for-desktop {
  .mobileOnly {
    display: none;
  }
}
