$colorBackgroundMainBlue: #1168f1;
$colorTextLightGrayMain: #f4f5fa;
$colorTextWhite: #fff;
$colorWhite: #fff;
$colorBlack: #000;
$colorTitleLight: #fcfcfc;
$colorTextBlackMain: #1d1d1d;
$colorTextBlack: #2b2b39;
$colorTextGray: #898989;
$colorTextC3: #c3c3c3;
$colorGold: #f90;
$colorGoldActive: #f70;
$colorRedError: #f1112c;
$colorGrey: #a4a3aa;
$colorLightGray: #f6f8fb;
$colorLightGrayHover: #e8effa;
$colorColdGrey: rgba(17, 104, 241, 0.05);
$colorBorderGray: #e5e6eb;
$colorBackgroundGray: #ddd;
$colorDarkBlue: #001a43;
$colorLightRed: #d84437;
$colorGreen: #00d060;
$colorAccountBlue: #1168f1;
$colorAccountBlueHover: #0c5ad4;
$colorAccountBlueFocus: #064dbe;
$colorInputHover: #f3f5ff;
$colorInputFocus: #eef1ff;

$colorAccountTextGray: #898989;
$colorAccountTextMiddleGray: #4f4f4f;
$colorAccountBlack: #1d1d1d;
$colorAccountPositiveGreen: #27ae60;
$colorAccountNegativeRed:#d60000;
$colorAccountPink: #bb6bd9;
$colorAccountPurple: #9b51e0;
$colorBackgroundNavbarBlue: #f8f9fd;
$colorLineLightBlue: #c7dbfb;
$colorAccountLightBlue: #cfe1fc;
$colorBgVip: #080410;
$colorBorderLightGray: #ececec;
$colorHoverGray: #f9f9f9;

$subInfoSmallFontSize: 6px;
$noteFontSize: 10px;
$mediumNoteFontSize: 11px;
$xxsFontSize: 12px;
$xsFontSize: 14px;
$smallFontSize: 16px;
$mediumFontSize: 18px;
$largeFontSize: 20px;
$xlFontSize: 22px;
$xxlFontSize: 24px;

$smallTitleFontSize: 46px;
$middleTitleFontSize: 36px;
$bigTitleFontSize: 72px;
